@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import '../scss/base';

html,
body {
    height: 100vh;
}

body {
    font-family: 'Poppins', sans-serif;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: var(--white-color);
    color: var(--base-color);
    font-size: 1rem;
    overflow-x: $hidden;
}
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: var(--grey-color-300);
}
::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
}
/* Button */
.text-mute {
    color: var(--grey-color-900);
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-weight: 700;
}
@media (min-width:1200px) {
    .h1,
    h1 {
        font-size: 34px;
    }
    h2,
    .h2 {
        font-size: 30px;
    }
    h3,
    .h3 {
        font-size: 25px;
    }
    h4,
    .h4 {
        font-size: 22px;
    }
    h5,
    .h5 {
        font-size: 20px;
    }
    h6,
    .h6 {
        font-size: 18px;
    }
}
label {
    font-weight: 500;
}
p {
    font-size: 16px;
    line-height: 1.3;
    color: var(--black-color);
    &:last-child {
        margin-bottom: 0;
    }
}

a {
    text-decoration: $none;
    color: var(--black-color);
    @include transition (all, .5s, ease-in-out);
}
a:hover {
    color: var(--primary-color);
}
.radius-10 {
    @include border-radius (10px);
}
.mw-50 {
    min-width: 50px;
    max-width: 50px;
}
.mw-150 {
    min-width: 150px;
    max-width: 150px;
}
.text-black {
    color: var(--black-color);
}
.bg-grey {
    background-color: var(--grey-color-100);
}

/* button */
a.btn {
    line-height: 2.1;
}
.btn {
    box-shadow: $none !important;
    transition: .5s;
    &-lg {
        font-size: 1rem;
        font-weight: 600;
        border-radius: 10px;
        padding: .85rem 1.5rem;
        min-height: 60px;
    }
    &-md {
        min-width: 140px;
        font-weight: 500;
        border-radius: 7px;
        padding: .75rem 1.5rem;
        @media (max-width:575px) {
            min-width: 120px;
        }
    }
    &-sm {
        font-weight: 600;
        border-radius: 7px;
        min-height: 46px;
        min-width: 70px;
        padding: .45rem 1rem;
        i {
            img {
                width: 26px;
            }
        }
    }
    &-primary {
        background-color: var(--primary-color) !important;
        border-color: var(--primary-color) !important;
        &:hover,
        &:focus {
            background-color: var(--black-color);
            border-color: var(--base-color);
        }
    }
    &-secondary {
        background-color: var(--secondary);
        border-color: var(--secondary);
        &:hover {
            background-color: var(--black-color);
            border-color: var(--black-color);
        }
        span {
            color: var(--grey-color-600);
        }
        small {
            color: var(--grey-color-600);
            font-size: 11px;
            display: $block;
        }
        em {
            margin-right: 4px;
        }
    }
    &-transparent {
        background: $transparent;
    }
    &-outline-primary {
        border-color: var(--primary-color);
        color: var(--primary-color);
        &:hover,
        &:focus {
            background: $transparent;
            color: var(--base-color);
            border-color: var(--base-color-100);
            svg path {
                fill: var(--base-color);
            }
        }
    }
    &-close {
        box-shadow: $none !important;
    }
}
/* Input field */
.form-control {
    min-height: 50px;
    font-size: 1rem;
    @include border-radius (10px);
    padding: .55rem 1.2rem;
    @media (min-width:1280px) {
        padding: .68rem 1rem;
    }
    &:focus {
        box-shadow: $none;
        border-color: var(--primary-color);
    }
    &-sm {
        min-width: 80px;
        min-height: 40px;
        font-size: 14px;
        border-radius: 5px;
        @media (min-width:1280px) {
            padding: .50rem 1rem;
        }
    }
}
select.form-control {
    background-image: url(../img/icons/down_icon.svg);
    background-position: $right calc(24px - 10px) center;
    background-repeat: $no-repeat;
    background-size: 15px;
}
@media (max-width:767px) {
    textarea.form-control {
        height: 90px;
    }
}
.text-primary {
    color: var(--primary-color) !important;
}

/* Back to Top */
.back-to-top {
    position: $fixed;
    display: $none;
    right: 25px;
    bottom: 25px;
    z-index: 99;
}

/*** Spinner ***/
#spinner {
    @include opacity (0);
    visibility: $hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
    &.show {
        transition: opacity .5s ease-out, visibility 0s linear 0s;
        visibility: $visible;
        @include opacity (1);
    }
}

.form-group {
    margin-bottom: 15px;
}

.bg {
    &-primary {
        background-color: $primary-color !important;
    }
}