@import 'variable';
@import 'flexbox';
@import 'mixin';


:root {
    --base-color: #1A1C20;
    --primary-color: #F06649;
    
    --black-color: #000000;
    --black-color-800:#4A4B68;
    --black-color-500:#1B1C31;

    --white-color: #ffffff;
    
    --red-color-100: #FFC4B7; 
    --red-color-200: #FBD2C9; 
    
    --grey-color-10000:#9A9A9A;
    --grey-color-900:#A4A4AD;
    --grey-color-700: #CCCCCC;
    --grey-color-400: #F2F2F2;
    --grey-color-300: #EDF3FA;
    --grey-color-200: #F3F6F8;
    --grey-color-100: #F7FAFF;

    --blue-color: #4382FF;
}

$transparent: transparent;
$danger-color:rgb(216, 39, 39);
$primary-color:#F06649;
$success-color:rgb(45, 194, 45);