// _variable.scss

$top: top;
$left: left;
$right: right;
$center: center;

$none: none;
$block: block;
$inline-block: inline-block;

$relative: relative;
$absolute: absolute;
$static: static;
$fixed: fixed;

$pointer: pointer;

$visible: visible;
$hidden: hidden;
$scroll: scroll;
$auto: auto;

$underline: underline;
$capitalize: capitalize;
$uppercase: uppercase;
$lowercase: lowercase;
$inherit: inherit;
$initial: initial;
$unset: unset;

$cover: cover;
$contain: contain;

$no-repeat: no-repeat;
