/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/scss/bootstrap";
@import "./assets/scss/base";
@import "./assets/scss/theme.scss";
/*  */
.title_hd {
    color: var(--base-color);
    font-size: 18px;
}
.up_pic {
    display: $inline-block;
    cursor: $pointer;
    img {
      @include border-radius (5px);
      width: 70px;
      max-height: 100px;
    }
}

.up_pic {
    display: $inline-block;
    cursor: $pointer;
    img {
      @include border-radius (5px);
      width: 70px;
      max-height: 100px;
    }
}

i {
    display: inline-block;
    margin-right: 10px;
    img {
        width: 20px;
    }
}

.main-box {
  color: var(--black-color-800);
  border: 1px solid #dee2e6;
  border-top: $none;
  padding: 25px;
  width: 100%;
}
.nav-tabs {
    overflow-x: auto;
    overflow-y: hidden;
    @include flexbox;
    flex-wrap: nowrap;
  a {
    color: var(--black-color-800) !important;
    white-space: nowrap;
  }
}

/* The switch - the box around the slider */
// .switch {
//   position: $relative;
//   display: $inline-block;
//   width: 60px;
//   height: 34px;
//   input {
//     @include opacity (0);
//     width: 0;
//     height: 0;
//   }
// }
// .switch {
//   .slider {
//     position: absolute;
//     cursor: pointer;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: #ccc;
//     -webkit-transition: .2s ;
//     transition: .2s;
//     &::before {
//       content: "";
//       position: absolute;
//       height: 26px;
//       width: 26px;
//       left: 4px;
//       bottom: 4px;
//       background-color: var(--white-color);
//       transition: 0.2s 0.2s ease-in-out;
//     }
//   }
//   input:checked+.slider,
//   input:focus+.slider {
//     background-color: var(--primary-color);
//   }
//   input:checked+.slider:before {
//     @include transform (translateX(26px));
//   }

//   /* Rounded sliders */
//   .slider.round {
//     @include border-radius (34px);
//   }
//   .slider.round:before {
//     @include border-radius (50%);
//   }
// }
//  new
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input[type="checkbox"] {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:#ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--primary-color);
}

input:checked + .slider:before {
  transform: translateX(26px);
}



// Modal
.modal {
  &-header {
    padding-left: 30px;
    padding-right: 30px;
  }
  &-body {
    padding: 20px 30px;
  }
}

// Table

.table {
  td {
    vertical-align: middle;
  }
}

.ngx-pagination {
  li {
    line-height: 2;
    min-width: 36px;
    text-align: $center;
    @include border-radius (4px !important);
    &.current {
      background: var(--primary-color);
    }
  }
}

// Tabs
.nav-tabs {
  .nav-link {
    padding: .90rem 1.5rem;
  }
}
.time-picker {
  display: inline-block;
  position: relative;
}

.time-picker input[type="time"] {
  padding: 8px;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

// .time-picker::before {
//   content: "\25BC";
//   position: absolute;
//   top: 50%;
//   right: 10px;
//   transform: translateY(-50%);
//   font-size: 14px;
//   pointer-events: none;
// }

//multi select dropdown
.custom-dropdown {
  position: relative;
  display: inline-block;
}

.custom-dropdown-toggle {
  display: none;
}

.custom-dropdown-label {
  padding: 10px 20px;
  background-color: #e9e9e9;
  border-radius: 4px;
  cursor: pointer;
}

.custom-dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 200px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.custom-dropdown-toggle:checked ~ .custom-dropdown-menu {
  display: block;
}

.custom-dropdown-item {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.custom-dropdown-item:hover {
  background-color: #f2f2f2;
}
//radiobutton
#specifyColor {
  accent-color:var(--primary-color);
}
